import { enUS, es, ptBR } from "../../i18n";

type TypeInternationalization = "enUS" | "es" | "ptBR";

const showTexts = (internationalization: TypeInternationalization) => {
  const i18n = { enUS, es, ptBR };

  return {
    callCenterPhone: i18n[internationalization].callCenterPhone,
    callCenterText: i18n[internationalization].callCenterText,
    copyRightText: i18n[internationalization].copyRightText,
    socialIcons: i18n[internationalization].socialIcons,
    thermsText: i18n[internationalization].thermsText,
    b2bMainText: i18n[internationalization].b2bMainText,
    b2bLinkText: i18n[internationalization].b2bLinkText,
    b2bLink: i18n[internationalization].b2bLink,
  };
};

export default showTexts;
