import styled from "styled-components";

export const FooterContainer = styled.div`
  width: 100%;
  background-color: #f8fafa;
  position: relative;
`;

export const FooterContainerMobile = styled.div`
  position: relative;
  @media (max-width: 390px) {
    > div > div:last-child {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;

export const FooterB2BText = styled.div`
  display: flex;
  color: #4f4f4f;
  font-family: "Inter", sans-serif;
  line-height: 110%;
  place-content: center;
  background-color: #f8fafa;
  padding-top: 18px;

  p {
    color: rgb(17, 87, 167);
    text-decoration: underline;
  }

  @media (min-width: 1200px) {
    top: 18px;
    z-index: 999;
    position: absolute;
    margin-left: 10% !important;
    padding-left: 1.5rem;
    padding-top: 0px;
    place-content: normal;
  }

  b {
    font-weight: 600;
  }

  a {
    text-decoration: none;
    color: #4f4f4f;
  }
`;
