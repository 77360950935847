import React from "react";
import { Footer as FooterDesktop, FooterMobile } from "zera";

import { showTexts } from "../../helpers";
import { FooterContainer, FooterContainerMobile, FooterB2BText } from "./style";

interface ISocialIcon {
  icon: string;
  link: string;
  visible: boolean;
}

interface IFooterProps {
  internationalization: "enUS" | "es" | "ptBR";
}

export const Footer: React.FunctionComponent<IFooterProps> = (props) => {
  const showTextsi18n = showTexts(props.internationalization);

  const [width, setWindowWidth] = React.useState(0);

  const showDesktopComponent: boolean = width > 850;

  const updateDimensions = () => {
    const width = window.outerWidth;
    setWindowWidth(width);
  };

  React.useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const formatSocialIcons = React.useCallback((socialIcons: ISocialIcon[]) => {
    let icons = [];
    socialIcons.map((social, _) => {
      if (social.visible) {
        icons.push({
          ...social,
          onPress: () => {
            window.open(social.link, "_blank");
          },
        });
      }
    });
    return icons;
  }, []);

  const handleNavigationTerms = React.useCallback(() => {
    window.open(
      "https://www.einstein.br/sobre-einstein/politicas-site",
      "_blank"
    );
  }, []);

  return (
    <footer id="footer">
      {showDesktopComponent ? (
        <FooterContainer>
          <FooterB2BText>
            <b>{showTextsi18n.b2bMainText}</b>&nbsp;
            <a href={showTextsi18n.b2bLink} target="_blank">
              <p>{showTextsi18n.b2bLinkText}</p>
            </a>
          </FooterB2BText>
          <FooterDesktop
            callCenter
            copyRight
            width="fixed"
            callCenterOnPress={() => {
              window.open(`tel:${showTextsi18n.callCenterPhone}`, "_top");
            }}
            callCenterText={showTextsi18n.callCenterText}
            callCenterPhone={showTextsi18n.callCenterPhone}
            copyRigthText={showTextsi18n.copyRightText}
            socialIcons={formatSocialIcons(showTextsi18n.socialIcons)}
            thermsText={showTextsi18n.thermsText}
            thermsOnPress={handleNavigationTerms}
          />
        </FooterContainer>
      ) : (
        <FooterContainerMobile>
          <FooterB2BText>
            <b>{showTextsi18n.b2bMainText}</b>&nbsp;
            <a href={showTextsi18n.b2bLink} target="_blank">
              <p>{showTextsi18n.b2bLinkText}</p>
            </a>
          </FooterB2BText>
          <FooterMobile
            callCenter
            copyRight
            callCenterOnPress={() => {
              window.open(`tel:${showTextsi18n.callCenterPhone}`, "_top");
            }}
            callCenterText={showTextsi18n.callCenterText}
            callCenterPhone={showTextsi18n.callCenterPhone}
            copyRigthText={showTextsi18n.copyRightText}
            socialIcons={formatSocialIcons(showTextsi18n.socialIcons)}
            thermsText={showTextsi18n.thermsText}
            thermsOnPress={handleNavigationTerms}
          />
        </FooterContainerMobile>
      )}
    </footer>
  );
};
